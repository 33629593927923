body
	margin: 0
	font-family: Arial, sans-serif
	background-color: #f0f0f0
	color: #333

.w-100
	width: 100%

.select
	width: 155px

.navbar
	background-color: #000
	color: #fff
	text-align: center
	padding: 20px

	.title
		font-family: 'Arial Black', sans-serif

.promo
	display: flex
	justify-content: space-between
	align-items: center
	padding: 20px
	background-color: #fff
	margin: 20px

	.promo-text
		flex: 1

		h2
			font-size: 24px
			color: #000

		.cta-button
			display: inline-block
			background-color: #FFD700
			color: #000
			padding: 10px 20px
			text-decoration: none
			border-radius: 5px
			font-weight: bold

.how-to-participate
	padding: 20px
	background-color: #fff
	margin: 20px

	h2
		font-size: 20px

.prizes
	padding: 20px
	background-color: #fff
	margin: 20px

	h2
		font-size: 20px

	ul
		list-style: none
		padding-left: 0

	li
		margin: 10px 0

.registration
	background-color: #fff
	padding: 20px
	margin: 20px

	h2
		font-size: 20px

	label, input
		display: block
		margin: 10px 0

	input.error
		border-color: red

	.open-scanner-button
		display: block
		background-color: #006eff
		color: #000
		padding: 5px 10px
		text-decoration: none
		border-radius: 5px
		font-weight: bold
		margin: 10px 0

		&.error
			border-color: red

		&.success
			border-color: green

	button.cta-button
		display: block
		background-color: #FFD700
		color: #000
		padding: 10px 20px
		text-decoration: none
		border-radius: 5px
		font-weight: bold

.addiitonal-info
	background-color: #fff
	padding: 20px
	margin: 20px

	h2
		font-size: 20px

	.addiitonal-info-components
		display: flex
		flex-direction: column
		gap: 10px

.invalid-promotion-wrapper
	height: 100vh
	display: flex
	flex-direction: column

	.invalid-promotion
		flex: 1
		display: flex
		align-items: center
		justify-content: center

		h1
			margin: 0

.main-page-wrapper
	min-height: 100vh
	display: flex
	flex-direction: column

	.main-page
		flex: 1

.footer
	text-align: center
	background-color: #000
	color: #fff
	padding: 10px


.button
	border: none
	font-family: Montserrat
	cursor: pointer
	transition: 0.3s
	border-radius: 20px
	background: #B0D20B
	padding: 0px 10px
	margin: 0px 10px
	color: white

.cta-button-space-top
	display: block
	background-color: #FFD700
	color: #000
	padding: 10px 20px
	margin-top: 10px
	text-decoration: none
	border-radius: 5px
	font-weight: bold



.exit-button
	display: block
	background-color: #006eff
	color: #000
	padding: 5px 10px
	margin-top: 20px
	text-decoration: none
	border-radius: 5px
	font-weight: bold

.root
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center

.modal
	display: none
	position: fixed
	top: 0
	left: 0
	width: 100%
	height: 100%
	background-color: rgba(0, 0, 0, 0.5)
	z-index: 10
	overflow: auto

.modal.open
	display: flex
	align-items: center
	justify-content: center

.modal-content
	background-color: #fff
	padding: 20px
	max-width: 600px
	border-radius: 8px
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)
	max-height: 90vh
	overflow-y: auto

.modal-content img
	max-width: 100%
	height: auto

.ul
	list-style: none
	padding: 0

.li
	cursor: pointer
	margin: 5px
	padding: 8px
	border: 1px solid #ccc
	border-radius: 4px
	color: black

.li-selected
	cursor: pointer
	margin: 5px
	padding: 8px
	border: 1px solid #ccc
	border-radius: 4px
	background-color: #4caf50
	color: #fff

.game-button
	margin-top: 10px
	padding: 10px
	background-color: #4caf50
	color: #fff
	border: none
	border-radius: 4px
	cursor: pointer